<template>
  <div>
    <v-card v-if="vfImages.length == 0" class="pa-0" style="background: black;">
      <v-skeleton-loader       
        class="myVueFlux"
        dark
        min-height="60vh"
        type="image, image, actions"
      ></v-skeleton-loader>
    </v-card>    
    <vue-flux
      :options="vfOptions"
      :images="vfImages"
      :transitions="vfTransitions"
      :captions="vfCaptions"
      ref="slider"
      v-if="vfImages.length > 0"
      class="myVueFlux"
    >
      <template v-slot:preloader>
        <flux-preloader />
      </template>

      <template v-slot:caption>
        <flux-caption v-slot="captionProps" class="caption">
          <div align="left" class="container-caption">
            <h2>{{ captionProps.text[$root.getTextlang()] }}</h2>
            <v-btn color="var(--color-primary)" class="btn-theme" 
              :dark="$store.state.corpInfo.darkTheme" 
              @click="GetEventData(captionProps)">
              {{ $t("actions.seeTickets") }}
            </v-btn>
          </div>
        </flux-caption>
      </template>

      <template v-slot:controls>
        <flux-controls />
      </template>

      <template v-slot:pagination v-if="vfImages.length > 1">
        <flux-pagination v-slot="itemProps">
          <div
            class="cube"
            :class="[{'black--text': !$store.state.corpInfo.darkTheme}, {'white--text': $store.state.corpInfo.darkTheme}]"
            item="itemProps"
            :style="{
              'background-color': itemProps.item.active ? 'white' : 'black',
            }"
            @click="$refs.slider.show(itemProps.item.index)"
          ></div>
        </flux-pagination>
      </template>
    </vue-flux>
  </div>
</template>

<script>
import {
  VueFlux,
  FluxCaption,
  FluxControls,
  FluxIndex,
  FluxPagination,
  FluxPreloader,
} from "vue-flux";

export default {
  name: "slider2",
  components: {
    VueFlux,
    FluxCaption,
    FluxControls,
    FluxIndex,
    FluxPagination,
    FluxPreloader,
  },
  data: () => ({
    vEntorno: null,
    vfOptions: {
      autoplay: true,
      bindKeys: true,
      enableGestures: true,
      autohideTime: 0,
    },
    vfImages: [],
    vfTransitions: [
      "fade",
      "kenburn",
      "swipe",
      "waterfall",
      "zip",
      "blinds2d",
      "blocks1",
      "blocks2",
      "round2",
    ],
    vfCaptions: [],
    refresh: 0
  }),
  created() {
    this.vEntorno = this.$vEntorno;
  },
  mounted() {
    this.vEntorno = this.$vEntorno;
    this.reload();
  },
  methods: {
    GetEventData(item) {
      let index = this.vEntorno.events.findIndex(
        (busca) => busca.id_event === item.text.id_event
      );
      this.$emit('openEvent', this.vEntorno.events[index], index);
    },
    reload() {
      let self = this;
      if (this.vfCaptions.length == 0) {
        if (this.vEntorno.events.length > 0) {
          this.vfImages = [];
          this.vfCaptions = [];

          for (var x = 0; x < this.vEntorno.events.length; x++) {
            if(this.vEntorno.events[x].img) {
              this.vfImages.push(
                this.$root.pathevens + this.vEntorno.events[x].img
              );
              this.vfCaptions.push(this.vEntorno.events[x].name);
            }
          }
          return false
        } else {
          if(self.refresh !== 10) {
              setTimeout(() => {
              self.reload()
              self.refresh += 1;
            }, 200); 
          }                   
        }
      }
      return false
    },
  },
};
</script>

<style scoped>
.btn-theme {
  margin-top: 10px;
}
.myVueFlux {
  height:60vh;
  z-index: 1 !important;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.cube {
  border: 1px solid white;
  padding: 0 8px 2px 8px;
  width: 20px;
  height: 20px;
  border-radius: 5px !important;
}

.caption {
  position: absolute;
  top: calc(100% - 205px);
}

.container-caption {
  max-width: 1200px;
  margin: auto;
  padding: 10px;
  padding-left: 30px;
}

@media only screen and (max-width: 1500px) {
  .caption {
    top: calc(100% - 176px);
  }
}

@media only screen and (max-width: 700px) {
  .caption {
    top: calc(100% - 156px);
  }

  .cube {
    margin-top: -10px;
    padding: 0 8px 2px 4px;
    width: 15px;
    height: 15px;
  }

  .myVueFlux {
    height: calc(100vh - 120px);
  }
}

@media (max-width: 500px) {
  .myVueFlux {
    height: calc(100vh - 160px);
  }
}
</style>