<template>
    <div align="center">
        <div :class="`box ${miniFlag ? 'box-mini':''}`" 
          :style="{backgroundImage: item.img?'url(' + $root.pathevens + item.img + ')':'' }">
            <div class="ribbon ribbon-top-left" v-if="RibonPosition == 1 && (item.event_config != 3 || item.package == true)">
              <span>{{ NameRibon[$root.getTextlang()] }}</span>
            </div>
            <div class="ribbon ribbon-top-right" v-if="RibonPosition == 2 && (item.event_config != 3 || item.package == true)">
              <span v-if="!item.package" 
              :class="[{'OutPage-event-flag': item.event_config == 1}, {'scheduled-event-flag': item.event_config == 4}, {'seating-event-flag': item.event_config == 5}]">
                {{ NameRibon[$root.getTextlang()] }}
              </span>
              <span v-else class="proximamente">{{ NameRibon2[$root.getTextlang()] }}</span>
            </div>
            <div class="ribbon ribbon-bottom-left" v-if="RibonPosition == 3 && item.event_config != 3"><span>{{ NameRibon[$root.getTextlang()] }}</span></div>
            <div class="ribbon ribbon-bottom-right" v-if="RibonPosition == 4 && item.event_config != 3"><span>{{ NameRibon[$root.getTextlang()] }}</span></div>
            <div class="DivContenText">
              <div class="Title" 
                :class="{
                  'mini-title': miniFlag, 
                  'titulo-animated': ((!miniFlag && item.name[$root.getTextlang()].length > 35) || (miniFlag && item.name[$root.getTextlang()].length > 25)) 
                  }"
                >
                {{ item.name[$root.getTextlang()] }}
              </div>
              <div v-if="item.id_eventcata != null">
                <div class="headerT">
                  <div>
                    <span class="spacer"></span>
                    <h1 :class="`${miniFlag ? 'minichange':''}`">
                      {{ getCategorie(item) }}
                    </h1>
                    <span class="spacer"></span>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name:'CardSite',
    data(){
        return{
          NameRibon: {
            ES: 'Lugares Limitados',
            EN: 'Limited Places'
          },
          NameRibon2: {
            ES: 'Próximamente',
            EN: 'Soon'
          }
        }
    },
    props:["RibonPosition", "item", "miniFlag"],
    methods: {
      getCategorie({ id_eventcata }) {
        let category 
        if(id_eventcata) {
          if(this.$store.getters['corpInfo/getEventCategorie'](id_eventcata)) category = this.$store.getters['corpInfo/getEventCategorie'](id_eventcata)[this.$root.getTextlang()]
          else category = this.$t("actions.categorias")[0].title
        }
        if(!id_eventcata) {
          category = this.$t("actions.categorias")[0].title
        }
        return category
      }
    }
}
</script>


<style scoped>
.titulo-animated {
  animation: animateTitulo 10s infinite linear;
}
.proximamente{
  background: var(--color-soon-flag) !important;
}
.OutPage-event-flag{
  background: var(--color-hyperlink-flag) !important;
}
.scheduled-event-flag{
  background: var(--color-schedule-flag) !important;
}
.seating-event-flag{
  background: var(--color-seating-flag) !important;
}
.box {
  position: relative;
  border-radius: 5px;
  max-width: 600px;
  width: 100%;
  height: 100%;
  min-height: 210px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0,0,0,.1);
  border: 3px solid #3F414A;
  background-size: cover;
  background-position: 100% 100%;
  overflow: hidden;
}
.box-mini {
  min-height: 150px !important;
}
.box:hover{
    z-index: 99;
    position: relative;
    margin-left: -2%;
    margin-top: -1%;
    height: 104%;
    margin-bottom: 1%;
    width: 104%;
    border: 3px solid white;
    cursor: pointer;
    box-shadow: 0px 4px 21px rgb(255 255 255 / 32%);
}
.headerT {
    display:table;
    width:100%;
    max-width:100%;
    padding: 0px 10px;
}
.headerT div {
    display:table-row;
    line-height:0.5em;
    font-size:2em;
    white-space:nowrap;
}
.headerT h1 {
    font-size:12px; /* Change font-size in header */
    overflow:hidden;
    display:table-cell;
    vertical-align:middle;
    width:1px;
    table-layout:fixed;
    padding: 0px 10px;
}
.headerT h1.minichange {
    font-size:10px !important;
    padding: 0px 4px;
}
.headerT span.spacer {
    display:table-cell;
}
.header h1 {
    padding:0 10px;
}
.headerT span.spacer:after {
    display:inline-block;
    width:100%;
    content:".";
    font-size:0;
    color:transparent;
    height:1px;
    background:white;
    vertical-align:middle;
    position:relative;
    top:-1px;
}
.headerT > a {
    font-size:.4em;
    vertical-align:middle;
    background:#25a2a4;
    color:#fff;
    text-transform:uppercase;
    font-family:monospace;
    border-radius:.5em;
    padding:.3em .5em;
    text-decoration:none;
}

.DivContenText{
  color: white;
  background: #000000DF;
  position: absolute;
  top: calc(100% - 56px);
  width: 100%;
  height: 57px;
  text-align: center;
  padding: 6px 0px 0px 0px;
}
.DivContenText .Title{
  font-size: 20px;
  white-space: nowrap;
}
.DivContenText .Title.mini-title{
  font-size: 14px !important;
}
body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #f0f0f0;
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid transparent;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 12px 0;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: 0px;
  left: 0px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -11px;
  top: 28px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -4px;
  right: -4px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -3px;
    left: -4px;
    z-index: 1;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}

@keyframes animateTitulo {
  0% {
    transform: translateX(100%); /* Inicia desde la posición original */
  }

  100% {
    transform: translateX(-180%); /* Inicia desde la posición original */
  }
}

@media only screen and (max-device-width: 1200px) {
  .box {
    width: 100%;
    min-height: 150px;
  }
  .ribbon span {
    font: 700 7px/1 'Lato', sans-serif;
    padding: 6px 0 6px 0;
  }
  .ribbon-top-left span {
    right: 6px;
    top: 23px;
  }
}
@media only screen and (max-device-width: 500px) {
  .box {
    width: 70%;
    min-height: 150px;
  }
  .box:hover{
    margin-left: 0%;
    margin-top: 0%;
    width: 70%;
  }
}
</style>