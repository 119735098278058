<template>
  <div 
    id="CategoryIcon" 
    :class="`container-category mx-3 ${active ? 'active' : ''}`"
    :style="cssVars"
  >
    <v-icon
      class="icon-category"
      :color="active ? 'var(--color-text-active)' : 'var(--color-text)'"
    >
      {{icon}}
    </v-icon>

    <div class="title-category mx-auto">
      {{title}}
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryIcon",
  props: [
    "title",
    "icon",
    "active",
    "cssStyle",
  ],
  computed: {
    cssVars: function () {
      return {
        '--font-size': this.cssStyle.fontSize || '16px',
        '--color-text': this.cssStyle.color || '#fff',
        '--color-text-active': this.cssStyle.colorActive || '#fff',
        '--bg-color': this.cssStyle.backgroundColor || 'transparent',
        '--bg-color-active': this.cssStyle.backgroundColorActive || 'teal',
        '--border-color': this.cssStyle.borderColor || '#fff',
        '--border-color-active': this.cssStyle.borderColorActive || '#fff',
      }
    }
  }
};
</script>

<style scoped>
.container-category {
  box-shadow: 0px 0px 0px 1px var(--border-color), 0px 0px 3px 1px var(--border-color);
  background-color: var(--bg-color);
  width: 7.7rem;
  height: 7.7rem;
  position: relative;
  border-radius: 50%;
  transition: all .2s ease-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-category.active {
  color: var(--color-text-active);
  background-color: var(--bg-color-active);
  box-shadow: 0px 0px 8px 1px var(--border-color-active), 0px 0px 8px 2px var(--border-color-active);
}

.container-category:not(.active):hover{
  cursor: pointer;
  box-shadow: 0px 0px 8px 1px var(--border-color), 0px 0px 8px 2px var(--border-color);
}

.icon-category {
  padding: 3px 10px 5px 10px;
  width: 100%;
  font-size: calc(var(--font-size) * 2.5) !important;
}

.title-category {
  max-width: 75%;
  max-height: 52%;
  overflow: hidden;
  text-align: center;
  padding-bottom: 5px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: var(--font-size);
  line-height: 20px;
  word-break: break-word;
}
</style> 