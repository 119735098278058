var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-0 ma-0 home-container",style:([
    {'height':(!_vm.vEntorno || !_vm.vEntorno.events || _vm.vEntorno.events.length === 0) || _vm.loadingEvents?'100%':'auto'},
    {'background-color':_vm.$store.state.corpInfo.darkTheme?'black':'#E2E6EF'}
    ])},[_c('div',{staticClass:"DivMaster"},[_c('div',{staticClass:"ContentDiv"},[(!_vm.loadingEvents && (_vm.vEntorno && _vm.vEntorno.events && _vm.vEntorno.events.length > 0))?_c('slider2',{ref:"slider2",on:{"openEvent":_vm.openSite}}):_vm._e(),_c('div',{staticClass:"DivBackground",style:({ 
          backgroundImage: _vm.$store.state.corpInfo.backgroundImage ? 
            `url(${_vm.$root.UrlCorpImages + _vm.$store.state.corpInfo.backgroundImage})` :'',
          })},[_c('div',{attrs:{"id":"stars"}}),_c('div',{attrs:{"id":"stars2"}}),_c('div',{attrs:{"id":"stars3"}}),(_vm.loadingEvents)?_c('div',{staticClass:"pb-5"},[_c('div',{style:([{'background':_vm.$store.state.corpInfo.darkTheme?'#363636BC':'#C3C3C372'}])},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"image, card, actions","height":"450","dark":_vm.$store.state.corpInfo.darkTheme}})],1),_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"1300px"}},[_c('v-row',{staticClass:"my-5 mx-auto d-flex flex-wrap flex-row justify-space-between align-center"},_vm._l((3),function(fakeEvent){return _c('v-col',{key:'fakeEvent-'+fakeEvent,attrs:{"cols":"12","sm":"6","md":"4","lg":"4"}},[_c('div',{style:([{'background':_vm.$store.state.corpInfo.darkTheme?'#363636BC':'#C3C3C372'}])},[_c('v-skeleton-loader',{attrs:{"type":"card","dark":_vm.$store.state.corpInfo.darkTheme}})],1)])}),1)],1)]):_vm._e(),((_vm.vEntorno && _vm.vEntorno.events && _vm.vEntorno.events.length > 0) && !_vm.loadingEvents)?_c('section',{staticClass:"grid-1"},_vm._l((_vm.vEntorno.events),function(item,index){return _c('div',{key:'CardSite' + index,on:{"click":function($event){return _vm.openSite(item, index)}}},[_c('CardSite',{attrs:{"RibonPosition":2,"item":item,"index":index}})],1)}),0):_vm._e(),((!_vm.vEntorno || !_vm.vEntorno.events || _vm.vEntorno.events.length === 0) && !_vm.loadingEvents)?_c('div',{staticClass:"text-center pa-5 pt-16 text-overline",class:[{'black--text': !_vm.$store.state.corpInfo.darkTheme}, {'white--text': _vm.$store.state.corpInfo.darkTheme}]},[_vm._v(" "+_vm._s(_vm.$t('messages.noEvents'))+" ")]):_vm._e()])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }