export const SITES_FUNCTIONS = {
  data() {
   return {
    vEntorno: null
   }  
  },
  methods: {
    async openSite(item, index) {

       if(item.package == true){
        this.$root.swalAlert('', this.$t('messages.soon') ,'')
        return false
      }

      if(item.event_config == 1 && item.url){
        window.open(item.url);
        return false
      }
      this.$router.push({
        name: 'eventPreview',
        query: { 
          e: item.name[this.$root.getTextlang()], 
          v: item.id_event
        }
      });
    },    
    filterRecommendedEvents() {
      this.vEntorno.events.forEach(event => {
        if(event.event_config === 3 && event.package == false) event.showRecommended = true;  
        else event.showRecommended = false; 
      });
    }
  }
 }